export const colors = [
    '#f44336', '#e91e63', '#9c27b0',
    '#673ab7', '#3f51b5', '#2196f3',
    '#03a9f4', '#00bcd4', '#009688',
    '#4caf50', '#8bc34a', '#cddc39',
    '#ffeb3b', '#ffc107', '#ff9800',
    '#ff5722', '#795548', '#9e9e9e',
    '#607d8b'
];

export const colorsPattern = [
    // ['#607d8b', '#0f4c75', '#3282b8', '#bbe1fa', '#e8505b', '#f9d56e', '#ff9800', '#14b1ab'],
    // ['#673ab7', '#f08a5d', '#b83b5e', '#6a2c70', '#e7305b', '#e2979c', '#ff5722', '#9bdeac'],
    // ['#ff0000', '#ffa500', '#ffff00', '#008000', '#0000ff', '#4b0082', '#ee82ee'],
    // [
    //     '#f44336', '#e91e63', '#9c27b0', '#673ab7',
    //     '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4',
    //     '#009688', '#4caf50', '#8bc34a', '#cddc39',
    //     '#ffeb3b', '#ffc107', '#ff9800', '#ff5722',
    //     '#795548', '#9e9e9e', '#607d8b'
    // ]
    ['#b1eb82','#eb8982', '#ee97b4', '#aa71b4', '#673ab7','#6a74aa', '#72a4cc', '#79e2dd']
]

export const colorsBasic = ['#b1eb82','#eb8982', '#ee97b4', '#aa71b4', '#673ab7','#6a74aa', '#72a4cc', '#79e2dd']

export const chars = [
    'A', 'B', 'C', 'D',
    'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L',
    'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T',
    'U', 'V', 'W', 'X',
    'Y', 'Z', '!', '§',
    '$', '%', '&', '/',
    '=', '?', '_', '<',
    '>', '^', '°', '*',
    '#', '-', ':', ';',
    '~', '(', ')'
];
